import {
    ChangeDetectionStrategy,
    ChangeDetectorRef,
    Component,
    ElementRef,
    EventEmitter,
    Input,
    Output,
    signal,
    ViewEncapsulation,
} from "@angular/core";
import { CommonModule } from "@angular/common";
import { TypeManagerDecorator } from "../../main/type.map.service";
import { Report } from "../../pojo/Report";
import { ReportService } from "../../services/report.service";
import { DocumentService } from "../../services/document.service";
import Context = Report.Context;
import { S25Util } from "../../util/s25-util";
import { S25LoadingInlineModule } from "../s25-loading/s25.loading.inline.module";
import ReportFormat = Report.ReportFormat;

@TypeManagerDecorator("s25-ng-report")
@Component({
    selector: "s25-ng-report",
    template: `
        @if (isLoading()) {
            <s25-ng-loading-inline-static [text]="'Downloading...'"></s25-ng-loading-inline-static>
        } @else {
            <span class="ngCpointer">
                <div class="ngMinImgDim ngImgText ngReport">
                    <a class="ngInlineBlock" (click)="clickReport()" tabindex="0" (keydown.enter)="clickReport()">
                        <span class="ngInlineBlock">{{ report.rpt_name }}</span>
                    </a>
                </div>
            </span>
            @if (showFileTypes()) {
                @for (format of formats; track format) {
                    <div class="c-margin-left--single reportFormatsLink">
                        @if (format.loading()) {
                            <s25-ng-loading-inline-static [text]="'Downloading...'"></s25-ng-loading-inline-static>
                        } @else {
                            <span class="{{ format.class }} ngInlineBlock ngMinImgDim ngImgText">
                                <a
                                    class="c-margin-left--half"
                                    (click)="runReport(format.itemId)"
                                    (keydown.enter)="runReport(format.itemId)"
                                    tabindex="0"
                                >
                                    <span class="ngInlineBlock">
                                        {{ format.itemName }}
                                    </span>
                                </a>
                            </span>
                        }
                    </div>
                }
            }
        }
    `,
    styles: ``,
    changeDetection: ChangeDetectionStrategy.OnPush,
    encapsulation: ViewEncapsulation.Emulated,
    standalone: true,
    imports: [CommonModule, S25LoadingInlineModule],
})
export class S25ReportComponent {
    @Input({ required: true }) report: Report.SimpleObject;
    @Input() context: Context = {};
    @Output() reportAction = new EventEmitter();

    isLoading = signal(false);
    isJrepLoading = signal(false);
    showFileTypes = signal(false);
    itemTypeName: string;

    constructor(
        private cdr: ChangeDetectorRef,
        private el: ElementRef,
    ) {}

    //Add a property to each format to make it easier to use in the template
    formats = ReportService.reportFormats.map((format) => {
        return { ...format, class: this.getFormatClass(format.itemId), loading: signal(false) };
    });

    getFormatClass(format: number) {
        switch (format) {
            case ReportFormat.PDF:
                return "ngIconPdf";
            case ReportFormat.Excel:
                return "ngIconExcel";
            case ReportFormat.RichText:
                return "ngIconRtf";
            case ReportFormat.HTML:
                return "ngIconHtml";
            case ReportFormat.Text:
                return "ngIconText";
        }
    }

    async clickReport() {
        if (this.report.rpt_engine === "JR") {
            this.showFileTypes.set(!this.showFileTypes());
        } else {
            await this.runReport();
            this.clearLoading();
        }
    }

    clearLoading() {
        this.isLoading.set(false);
        this.formats.forEach((f) => f.loading?.set(false));
    }

    async runReport(format?: ReportFormat) {
        let loadingFormat;
        if (format) {
            loadingFormat = this.formats.find((f) => f.itemId === format);
            loadingFormat?.loading?.set(true);
        } else {
            this.isLoading.set(true);
        }

        //Can't run if Jreport doesn't have a document
        if (this.report.rpt_engine === "JR" && S25Util.isUndefined(format)) {
            return;
        }

        let context: Context = { ...this.context };

        //special contract report that takes an event id and a document run id
        //the document run id is a document template that has been hydrated with the
        //event in context and then a PDF of that is returned
        if (this.report.document_id) {
            context.documentRunId = await DocumentService.getEventDocumentRunId(
                this.report.document_id,
                this.context?.num_parm1,
            );

            if (this.report.type === "reservation") {
                if (!this.context.char_parm4) {
                    alert("Select a reservation");
                    return;
                }
                context.char_parm4 = this.context.char_parm4;
            }
            if (this.report.type === "invoice") {
                if (!this.context?.num_parm2) {
                    alert("Select an invoice");
                    return;
                }
                context.num_parm2 = this.context.num_parm2;
            }
        } else {
            context.num_parm2 = this.context.num_parm2;
        }

        const data = await ReportService.runReportRequest(this.report, context, format, null, null, null);

        this.clearLoading();
        return data;
    }
}
